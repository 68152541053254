import { template as template_bded5a1c7ab94292a398c521d0332ef4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bded5a1c7ab94292a398c521d0332ef4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
