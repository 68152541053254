import { template as template_eef2ed0840444aafbb7d2e561da086f8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_eef2ed0840444aafbb7d2e561da086f8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
