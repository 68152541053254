import { template as template_f3508da51ad2466986cc8a1dca211453 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f3508da51ad2466986cc8a1dca211453(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
