import { template as template_e8d9ec2ba8d94f11bf28865da00b7f8d } from "@ember/template-compiler";
const FKText = template_e8d9ec2ba8d94f11bf28865da00b7f8d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
